@font-face {
    font-family: 'Lora-Regular';
    src: url(../../assets/fonts/app/Lora-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora-Italic';
    src: url(../../assets/fonts/app/Lora-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora-Medium';
    src: url(../../assets/fonts/app/Lora-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora-MediumItalic';
    src: url(../../assets/fonts/app/Lora-MediumItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora-Semibold';
    src: url(../../assets/fonts/app/Lora-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora-SemiboldItalic';
    src: url(../../assets/fonts/app/Lora-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora-Bold';
    src: url(../../assets/fonts/app/Lora-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora-BoldItalic';
    src: url(../../assets/fonts/app/Lora-BoldItalic.ttf) format('truetype');
}

/*    */
@font-face {
    font-family: 'SemplicitaPro-Italic';
    src: url(../../assets/fonts/app/SemplicitaPro-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-Light';
    src: url(../../assets/fonts/app/SemplicitaPro-Light.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-LtIt';
    src: url(../../assets/fonts/app/SemplicitaPro-LtIt.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-Medium';
    src: url(../../assets/fonts/app/SemplicitaPro-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-MedIt';
    src: url(../../assets/fonts/app/SemplicitaPro-MedIt.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-Semibold';
    src: url(../../assets/fonts/app/SemplicitaPro-Semibold.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-SemibdIt';
    src: url(../../assets/fonts/app/SemplicitaPro-SemibdIt.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-Bold';
    src: url(../../assets/fonts/app/SemplicitaPro-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro-BoldItalic';
    src: url(../../assets/fonts/app/SemplicitaPro-BoldItalic.otf) format('opentype');
}

@font-face {
    font-family: 'SemplicitaPro';
    src: url(../../assets/fonts/app/SemplicitaPro.otf) format('opentype');
}


:root {
    --color-transparent: 'transparent';
    --color-background: #ffffff;
    --color-darkerBackground: #ededed;
    --color-greyBackground: #f5f5f5;

    --color-text: #2E3F57;
    --color-placeholder: #BFC0C4;
    --color-primary: #3AABB0;
    --color-secondary: #81E2E6;
    --color-red1: #F1364E;

    --color-anger: #f53d3d;
    --color-light: #f4f4f4;
    --color-dark: #222;
    --color-black: #000;
    --color-white: #fff;
    --color-red: #f00;

    --color-gray1: #6C6C6C;
    --color-gray2: #EDF9FA;
    --color-gray3: #EFEFEF;
    --color-gray4: #AAA8BF;
    --color-gray5: #BFC0C4;
}


@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container, .container-sm, .container-md {
        max-width: 688px;
    }
}

@media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
        max-width: 892px;
    }
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1080px;
    }
}

@media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
        max-width: 1240px !important;
    }
}

input, textarea {
   outline: none;
}

.display-hide {
    display: none;
}

.display-flex {
    display: flex;
}

iframe {
    display: none;
}

body {
    margin: 0;
}

.mh0 {
    margin-left: 0;
    margin-right: 0;
}

.mv0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mt4 {
    margin-top: 4px;
}

.mt6 {
    margin-top: 6px;
}

.mt8 {
    margin-top: 8px;
}

.mt12 {
    margin-top: 12px;
}

.mt16 {
    margin-top: 16px;
}

.mt18 {
    margin-top: 18px;
}

.mt20 {
    margin-top: 20px;
}

.ph0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pl-10 {
    padding-left: 10px !important;
    padding-right: 0 !important;
}

.pr-10 {
    padding-left: 0 !important;
    padding-right: 10px !important;
}

.ph-20 {
    padding-left: 60px;
    padding-right: 60px;
}

.hidden {
    display: none;
}

.flex_1 {
    flex: 1;
}

.flex_wrap {
    flex-wrap: wrap;
}

.pos_relative {
    position: relative;
}

.row_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.flex_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.center-align {
    display: flex;
    justify-content: center;
}

.align-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-col-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.align-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.align-row-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.row-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.h100 {
    height: 100%;
}

.w100 {
    width: 100%;
}

.btn-style {
    cursor: pointer;
}

.react-confirm-alert-overlay {
    z-index: 1001 !important;
    background: rgba(0, 0, 0, 0.3) !important;
}

.react-confirm-alert-body {
    text-align: center !important;
    font-size: 18px;
}

.react-confirm-alert-button-group {
    justify-content: center !important;
    align-items: center !important;
}


.cookie-bar {
    align-items: center;
    background-color: #2b3c47;
    bottom: 0;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-family: SemplicitaPro;
    height: 81px;
    justify-content: space-around;
    left: 0;
    opacity: .95;
    position: fixed;
    right: 0;
    width: 100vw;
    z-index: 12;
}

@media (max-width: 767px) {
    .cookie-bar {
        height: auto;
        padding: 25px 15px;
        z-index: 2000000001 !important;
    }
}


@media (max-width: 767px) {
    .cookie-bar-content {
        flex-direction: column;
        text-align: center;
    }
}

.cookie-bar-content {
    align-items: center;
    display: flex;
    margin: 0 auto;
}

.cookie-bar-content a {
    color: #299edf;
    position: relative;
    text-decoration: none;
    transition: color .2s ease;
}

@media (min-width: 768px) {
    .cookie-button {
        margin-left: 60px;
    }
}

@media (max-width: 767px) {
    .cookie-button {
        margin-top: 10px;
        text-align: center;
        width: 100%;
    }    
}

.cookie-button {
    background-color: #2a9dde;
    border: 0;
    border-radius: 50px;
    color: #fff;
    height: 48px;
    line-height: 48px;
    padding: 0 42px;
}
