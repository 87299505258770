.planna-chat-boot-widget-container-anna {
  padding: 0px 15px;
  display: grid;
  height: inherit;
  position: relative;
}

.planna-chat-boot-widget-container-anna .scroll-content {
  overflow: auto;
  padding-bottom: 90px;
  padding-top: 20px;
  display: grid;
}

.planna-chat-boot-widget-container-anna-q-a-list {
  height: 100%;
}

.planna-chat-boot-widget-container-anna .scroll-content::-webkit-scrollbar {
  display: none;
}

.planna-chat-boot-widget-container-anna h4 {
  color: #2e3f57;
  font-family: Lora-Medium;
  font-weight: 500;
  font-size: 18px !important;
  margin-bottom: 20px;
}

.planna-chat-boot-widget-container-anna .conversation-item {
  display: flex;
  margin-bottom: 20px;
}

.planna-chat-boot-widget-container-anna .question-item {
  margin-left: 40px;
  flex-direction: row-reverse;
}

.planna-chat-boot-widget-container-anna .answer-item {
  margin-right: 40px;
}

.planna-chat-boot-widget-container-anna .answer-item .message-icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.planna-chat-boot-widget-container-anna .question-item .message-icon {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

.planna-chat-boot-widget-container-anna .conversation-item p {
  color: #272f38;
  font-family: SemplicitaPro;
  font-size: 14px !important;
  margin-bottom: 0px;
  margin-top: 3px;
}

.planna-chat-boot-widget-container-anna-input-footer {
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-top: 10px;
  background-color: #f8f6f6;
}
.planna-chat-boot-widget-container-anna-input-footer div {
  width: 100%;
  position: relative;
}

.planna-chat-boot-widget-container-anna-input {
  color: #2e3f57;
  font-family: SemplicitaPro;
  font-size: 14px !important;
  background-color: #fff;
  border-radius: 12px;
  border: solid 1.5px #e0e0e0;
  height: 50px;
  width: 100%;
  padding-left: 10px;
  padding-right: 40px;
}

.planna-chat-boot-widget-container-anna-send-button {
  outline: none;
  border-width: 0px;
  background-color: #fff;
  position: absolute;
  right: 1.5px;
  top: 1.5px;
  height: 47px;
  width: 40px;
  border-radius: 12px;
}

.planna-chat-boot-widget-container-anna-suggestion-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.planna-chat-boot-widget-container-anna-suggestion-container .suggestion-item {
  border: solid 1.5px rgba(191, 192, 196, 0.5);
  border-radius: 10px;
  padding: 8px 12px;
  display: grid;
  margin: 5px;
  background-color: #fff;
}
.planna-chat-boot-widget-container-anna-suggestion-container
  .suggestion-item
  .suggestion-item-title {
  color: #272f38;
  font-family: SemplicitaPro-Bold;
  font-size: 12px !important;
}

.planna-chat-boot-widget-container-anna-suggestion-container
  .suggestion-item
  .suggestion-item-desc {
  color: #595e66;
  font-family: SemplicitaPro;
  font-size: 12px !important;
}

.planna-chat-boot-widget-activity-card {
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
}

.planna-chat-boot-widget-activity-card .activity-card-header {
  display: flex;
  justify-content: space-between;
}
.planna-chat-boot-widget-activity-card .activity-card-title {
  color: #2e3f57;
  font-family: Lora-Bold;
  font-weight: 500;
  font-size: 16px !important;
  margin-bottom: 0px;
  flex-basis: fit-content;
  margin-right: 10px;
  line-height: 20px;
}
.planna-chat-boot-widget-activity-card .activity-card-task-circle {
  background-color: #efefef;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: grid;
  align-content: center;
}
.planna-chat-boot-widget-activity-card
  .activity-card-task-circle
  .activity-card-task-circle-count {
  color: #595e66;
  font-family: SemplicitaPro-Bold;
  font-weight: 700;
  font-size: 14px !important;
  text-align: center;
  line-height: 16px;
}
.planna-chat-boot-widget-activity-card
  .activity-card-task-circle
  .activity-card-task-circle-label {
  color: #595e66;
  font-family: SemplicitaPro-Bold;
  font-weight: 700;
  font-size: 8px !important;
  text-align: center;
}

.planna-chat-boot-widget-activity-card .activity-card-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.activity-card-container-span-title {
  color: #595e66;
  font-family: SemplicitaPro-Bold;
  font-weight: 700;
  font-size: 12px !important;
  margin-left: 3px;
}

.activity-card-container-view-button,
.service-card-container-view-button {
  background-color: #3aabb0;
  color: #fff;
  font-family: SemplicitaPro-Medium;
  font-size: 14px !important;
  text-align: center;
  padding: 10px 0px;
  border-width: 0px;
  border-radius: 50px;
  width: 100%;
}

.planna-chat-boot-widget-service-card {
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
}

.service-card-profile-picture {
  height: 40px;
  width: 40px;
  border-radius: 40px;
}

.service-card-header {
  display: flex;
}

.service-card-header .service-card-title {
  color: #2e3f57;
  font-family: Lora-Bold;
  font-weight: 500;
  font-size: 16px !important;
  margin-bottom: 0px;
  flex-basis: fit-content;
  line-height: 20px;
}

.service-card-container-span-title {
  color: #6c6c6c;
  font-family: SemplicitaPro-SemiBold;
  font-weight: 700;
  font-size: 11px !important;
  line-height: 14px;
}

.service-card-container-span-tag {
  color: #595e66;
  font-family: SemplicitaPro-Medium;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 14px;
  background-color: #f8f6f6;
  padding: 5px;
  border-radius: 50px;
  margin: 2.5px;
}
.planna-chat-boot-widget-service-card .service-card-container {
  display: flex;
  margin: 10px 0px;
}

.service-card-profile-picture-verify {
  position: absolute;
  bottom: 0px;
  right: -3px;
  height: 14px;
  width: 14px;
}

.planna-chat-boot-widget-container-anna-reset-button {
  border: solid 1.5px rgba(191, 192, 196, 0.5);
  border-radius: 10px;
  padding: 10px 10px;
  background-color: #fff;
  color: #272f38;
  font-family: SemplicitaPro-Medium;
  font-size: 14px !important;
  margin: 20px 0px;
  margin-top: 40px;
}

.planna-chat-boot-widget-container-chat-whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(69, 247, 102, 0.1);
  height: 47px;
  position: absolute;
  width: 100%;
  z-index: 20;
}

.planna-chat-boot-widget-container-chat-whatsapp-span {
  color: #272f38;
  font-family: SemplicitaPro-Medium;
  font-size: 16px !important;
  margin-right: 20px;
}
