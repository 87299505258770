.register-dialog-root {
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 87px;
}

.planna-register-dialog {
  background-color: #f8f6f6;
  height: calc(100% - 107px);
  max-height: 796px;
  width: calc(100% - 40px);
  max-width: 400px;
  border-radius: 20px;
  position: fixed;
  transition: right 0.5s;
  outline: none;
  z-index: 1000;
  overflow: auto;
  padding: 20px;
  box-shadow: 0px 2px 12px 0px rgba(108, 108, 108, 0.5);
}

.planna-register-dialog::-webkit-scrollbar {
  display: none;
}

.planna-register-dialog-success {
  height: unset;
}

.planna-register-form-title {
  font-family: Lora-Bold;
  font-size: 32px;
  text-align: center;
  color: #2e3f57;
  margin-bottom: 20px;
}
.planna-register-input {
  color: #2e3f57;
  font-family: SemplicitaPro;
  font-size: 14px !important;
  background-color: #fff;
  border-radius: 12px;
  border: solid 1.5px #e0e0e0;
  height: 50px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}

.planna-register-input::placeholder {
  color: #bfc0c4;
}

.planna-register-input-right-button {
  border: 0px;
  background-color: transparent;
  position: absolute;
  right: 0px;
  height: 50px;
  width: 41px;
  z-index: 10;
}

.planna-register-submit-button {
  background-color: #2e3f57;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px !important;
  color: #fff;
  font-size: 16px !important;
  font-family: SemplicitaPro-Bold !important;
  width: 100%;
  outline: none;
  border-width: 0px;
}
.planna-register-form .disabled {
  background-color: #bfc0c4;
}

.planna-register-policy-msg {
  color: #2e3f57;
  font-size: 14px !important;
  font-family: SemplicitaPro-Medium !important;
  text-align: center;
  margin-bottom: 15px;
}

.planna-register-policy-msg a {
  color: #3aabb0 !important;
}

.planna-register-referral-input-title {
  color: #3aabb0;
  font-size: 16px !important;
  font-family: SemplicitaPro-Medium !important;
  text-align: center;
  margin-bottom: 10px;
}

.planna-register-option-title {
  color: #2e3f57;
  font-size: 16px !important;
  font-family: SemplicitaPro-Medium !important;
  text-align: center;
  margin-bottom: 10px;
}
.planna-register-social-title {
  color: #595e66;
  font-size: 14px !important;
  font-family: SemplicitaPro-Medium !important;
  text-align: center;
}

.planna-address-suggestion-container {
  position: absolute;
  background-color: #fff;
  width: calc(100% - 40px);
  z-index: 12;
  border-radius: 12px;
  border: solid 1px #efefef;
  max-height: 225px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: -15px;
}

.planna-address-suggestion-container .planna-address-suggestion-item {
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #efefef;
  display: flex;
  justify-content: space-between;
}

.planna-address-suggestion-container .planna-address-suggestion-item p {
  color: #272f38;
  font-size: 14px;
  font-family: SemplicitaPro-Medium;
  margin-bottom: 0px;
}

.planna-register-social-icon {
  height: 72px;
  width: 72px;
  border-radius: 72px;
  background-color: #fff;
  border: 1px solid #efefef;
  justify-content: center;
  align-items: center;
  display: flex;
}

.planna-register-social-icon-active {
  background-color: rgba(129, 226, 230, 0.1);
  border: 3px solid #3aabb0;
}

.planna-register-form .planna-register-input-error-msg {
  color: #f1364e;
  font-size: 14px;
  font-family: SemplicitaPro-Medium;
  margin-bottom: 15px;
  margin-top: -10px;
}

.planna-register-success-container img {
  justify-self: center;
  margin-bottom: 20px;
}

.planna-register-success-container h3 {
  font-family: Lora-Bold;
  font-size: 32px;
  text-align: center;
  color: #2e3f57;
  margin-bottom: 10px;
}

.planna-register-success-container p {
  font-family: SemplicitaPro;
  font-size: 16px;
  text-align: center;
  color: #595e66;
  margin-bottom: 20px;
}

.planna-register-success-container button,
.planna-register-success-container a {
  background-color: #2e3f57;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px !important;
  color: #fff;
  font-size: 16px !important;
  font-family: SemplicitaPro-Bold !important;
  width: 100%;
  outline: none;
  border-width: 0px;
}
