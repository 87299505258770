//
// footer.scss
//
.footer {
  background: $footer;
  position: relative;
  color: $gray-500;
  padding-bottom: 20px;
  .logo-footer {
    &:focus {
      outline: none;
    }
    img {
      width: 142px;
      height: 50px;
      object-fit: contain;
    }
  }
  .footer-head {
    font-family: SemplicitaPro-SemiBold, serif;
    font-size: 18px !important;
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 0 !important;
  }
  .foot-subscribe {
    .form-control {
      background-color: lighten($black, 10%);
      border: 1px solid lighten($black, 10%);
      color: $light;
      &:focus {
        box-shadow: none;
      }
    }
    &.foot-white {
      .form-control {
        color: $gray-500;
      }
    }
    input {
      &::placeholder {
        color: $gray-500;
      }
    }
  }
  .text-foot {
    font-family: SemplicitaPro;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 24px;
    color: #ffffff;
  }
  .copy-right {
    font-family: 'SemplicitaPro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fff;
  }
  .company-info {
    font-family: SemplicitaPro-Light, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
  }
  .company-address {
    font-family: SemplicitaPro-Medium, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
  }
  .subscribe-column {
    padding-left: 0px;
    padding-right: 0px;
    justify-content: space-between;
  }
  .subscribe-column img {
    height: 80px;
  }
  .subscribe-column input,
  .subscribe-column button {
    height: 49px;
  }
  .footer-list {
    margin-bottom: 0;
    li {
      margin-bottom: 10px;
      a {
        transition: all 0.5s ease;
        &:hover {
          color: lighten($gray-500, 20%);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.footer-border,
  &.footer-bar {
    //border-top: 1px solid lighten($footer, 5%);
  }
  &.footer-bar {
    padding: 20px 0;
  }
}

@media (max-width: 992px) {
  .footer {
    .footer-head {
      font-size: 17px !important;
      line-height: 24px;
    }

    .text-foot {
      font-size: 16px;
      line-height: 20px;
    }

    .copy-right {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    .footer-column {
      text-align: center;
      justify-content: center;
    }

    .subscribe-column {
      padding-left: 30px;
      padding-right: 30px;
    }

    .footer-head {
      font-size: 16px !important;
      line-height: 20px;
    }

    .text-foot {
      font-size: 14px;
      line-height: 18px;
    }

    .copy-right {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
