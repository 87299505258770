.planna-chat-boot-widget-button {
  background-color: #2e3f57;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 45px;
  bottom: 40px;
  transition: right 0.5s;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  filter: drop-shadow(0px 0px 10px rgba(108, 108, 108, 0.4));
}

.planna-chat-boot-widget-container {
  background-color: #f8f6f6;
  height: calc(100% - 200px);
  max-height: 640px;
  width: calc(100% - 40px);
  max-width: 340px;
  border-radius: 20px;
  position: fixed;
  right: 45px;
  bottom: 124px;
  transition: right 0.5s;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  filter: drop-shadow(0px 30px 30px rgba(108, 108, 108, 0.5));
  overflow: hidden;
}

.planna-chat-boot-widget-container-tab-bar {
  background-color: #fff;
  position: absolute;
  width: 100%;
}

.planna-chat-boot-widget-container-tab-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.planna-chat-boot-widget-container-tab-bar ul li {
  float: left;
  height: 60px;
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.planna-chat-boot-widget-container-tab-bar ul li .indicator {
  position: absolute;
  height: 2.5px;
  background-color: transparent;
  width: 80%;
  bottom: 0px;
}

.planna-chat-boot-widget-container-tab-bar ul li .active-indicator {
  background-color: #2e3f57;
}

.planna-chat-boot-widget-container-tab-bar ul li a {
  color: #2e3f57;
  font-family: SemplicitaPro;
  font-size: 16px !important;
}

.planna-chat-boot-widget-container-tab-bar ul li .active {
  font-family: SemplicitaPro-Bold;
}

.planna-chat-boot-widget-container-contact {
  padding: 20px;
  height: inherit;
}

.planna-chat-boot-widget-container-contact h4 {
  color: #2e3f57;
  font-family: Lora-Medium;
  font-weight: 500;
  font-size: 18px !important;
  margin-bottom: 20px;
}

.planna-chat-boot-widget-container-contact-input {
  color: #2e3f57;
  font-family: SemplicitaPro;
  font-size: 14px !important;
  background-color: #fff;
  border-radius: 12px;
  border: solid 1.5px #e0e0e0;
  height: 50px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.planna-chat-boot-widget-container-contact textarea {
  height: 275px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-width: 1.5px !important;
  border-color: #e0e0e0 !important;
}

.planna-chat-boot-widget-container-contact-input::placeholder {
  color: #bfc0c4;
}

.planna-chat-boot-widget-container-contact button {
  background-color: #4cd7ad;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px !important;
  color: #fff;
  font-size: 16px !important;
  font-family: SemplicitaPro-Bold !important;
  width: 100%;
  outline: none;
  border-width: 0px;
}

@media (max-width: 720px) {
  .planna-chat-boot-widget-container {
    right: 20px;
    bottom: 90px;
  }
  .planna-chat-boot-widget-button {
    height: 55px;
    width: 55px;
    right: 20px;
    bottom: 20px;
  }
}

@media (max-width: 420px) {
  .planna-chat-boot-widget-container {
    max-width: unset;
  }
}
